import { InputField } from 'leeloo-react/component/form/field/InputField'
import { ContextForm } from 'leeloo-react/component/form/Form'
import { useContext } from 'react'
import { useResolveItem } from '../hooks/useResolveItem'

type Props = {
  index: number
}

/**
 * Affiche un item du configurateur
 */
export const ConfiguratorItem = (props: Props) => {
  const form = useContext(ContextForm);
  const itemResolver = useResolveItem(true);
  const item = itemResolver(props.index);

  const keyItem = 'itemList['+props.index+'].qte';

  return (
    <div className='configurator-item'>
      <div className='head'>
        <img
          src={`/assets/img/${item.image}`}
          alt={item.libelle}
          className='thumb'
        />
        <span className='title'>{item.libelle}</span>
      </div>
      <div className='input-group'>
        <button 
          onClick={() => {
            if (item.qte > 0) {
              form.onChangeFieldValue(keyItem, item.qte - 1);
            }
          }} 
          className='btn btn-outline-dark' 
          type='button'
        >
          <span className='material-icons'>remove</span>
        </button>
        <InputField
          className='form-control text-primary'
          name={keyItem}
          type='number'
          min='0'
        />
        <button
          onClick={() => form.onChangeFieldValue(keyItem, item.qte + 1)} 
          className='btn btn-outline-dark'
          type='button' >
          <span className='material-icons'>add</span>
        </button>
      </div>
    </div>
  )
}