import { CheckField } from 'leeloo-react/component/form/field/CheckField'
import { InputField } from 'leeloo-react/component/form/field/InputField'
import { SelectField } from 'leeloo-react/component/form/field/SelectField'
import { Implantation } from '../../model/api-dto'
import { DevisConfiguratorType } from '../../model/configurator/DevisConfiguratorType'

export const Caracteristique = () => {
  return (
    <>
      <div className='card-header'>
        <span className='material-icons text-secondary'>settings</span> Caractéristiques
        <a className='toggle-collapse' data-bs-toggle='collapse' href='#panel-config'>
          <span className='material-icons text-body'>expand_less</span>
        </a>
      </div>
      <div id='panel-config' className='collapse show'>
        <div className='card-body'>
          <div className='d-block mb-2'>
            <label className='form-label'>Contremarque</label>
            <InputField name={'label'} />
          </div>

          <div className='form-check form-switch mb-2'>
            <label className='form-check-label'>Meubles montés</label>
            <CheckField<DevisConfiguratorType> name='meubleMonte' />
          </div>
          <div className='form-check form-switch mb-2'>
            <label className='form-check-label'>Modèle sans poignées</label>
            <CheckField<DevisConfiguratorType> name='sansPoignee' />
          </div>
          <div className='form-check form-switch mb-2'>
            <label className='form-check-label'>Îlot central</label>
            <CheckField<DevisConfiguratorType> name='ilotCentral' />
          </div>
          <div className='d-flex justify-content-between align-items-center mb-2'>
            <label className='form-label w-50 m-0'>Implantation</label>
            <SelectField<DevisConfiguratorType, number> name='implantation' className='w-50'
              optionsList={[
                { label: 'U', value: Implantation.U },
                { label: 'L', value: Implantation.L },
                { label: 'Linéaire', value: Implantation.Lineaire }
              ]} />
          </div>
          <div className='d-flex justify-content-between align-items-center mb-2'>
            <label className='form-label w-50 m-0'>Marque des meubles</label>
            <SelectField<DevisConfiguratorType, string> name='marque' className='w-50'
              optionsList={[
                { label: 'Nobilia', value: 'Nobilia' },
                { label: 'Morel', value: 'Morel' },
              ]} />
          </div>
          {/* <div className='d-flex justify-content-between align-items-center mb-2'>
            <label className='form-label w-75 m-0'>Marque des meubles</label>
            <select className='form-select' id='input-4'>
              <option selected={true} disabled={true}>
                Choisissez…
              </option>
              <option value='1'>Option 1</option>
              <option value='2'>Option 2</option>
              <option value='3'>Option 3</option>
            </select>
          </div>
          <div className='d-flex justify-content-between align-items-center'>
            <label className='form-label w-75 m-0'>Implantation</label>
            <select className='form-select' id='input-5'>
              <option selected={true} disabled={true}>
                Choisissez…
              </option>
              <option value='1'>Option 1</option>
              <option value='2'>Option 2</option>
              <option value='3'>Option 3</option>
            </select>
          </div> */}
        </div>
      </div>
    </>
  )
}
