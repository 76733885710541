import { CheckField } from 'leeloo-react/component/form/field/CheckField'
import { InputField } from 'leeloo-react/component/form/field/InputField'
import { SelectField } from 'leeloo-react/component/form/field/SelectField'
import { ContextForm } from 'leeloo-react/component/form/Form'
import { useContext } from 'react'
import { ArticleDto } from '../../model/api-dto'
import { useResolvePrestation } from '../hooks/useResolvePrestation'

type Props = {
  index: number
}

/**
 * Affiche un item du configurateur
 */
export const PrestationList = (props: Props) => {
  const form = useContext(ContextForm);

  const prestationResolver = useResolvePrestation();
  const item = prestationResolver(props.index);
  const keyItem = 'itemList[' + props.index + '].qte';
  const codeItem = 'itemList[' + props.index + '].codeItem';
  const priceItem = 'itemList[' + props.index + '].price';
  const article = item.articles?.find((a : ArticleDto) => a.code == item.codeItem);
  
  return (
    <div>
      <div className='form-check form-switch mb-2'>
        <label className='form-check-label'>{item.libelle}</label>
        <CheckField name={keyItem} />
      </div>
      {(item.qte ? (<div className='d-flex justify-content-between align-items-center mb-2'>
            <SelectField<any, string> name={codeItem} className='w-100'
              onChangeValue={(v: any) => {
                const article = item.articles?.find((a : ArticleDto) => a.code == v);
                form.onChangeFieldValue(priceItem, article ? article.prixHT : 0);
              }}
              optionsList={item.articles ? item.articles!.map((a : ArticleDto) => { return { label : a.libelle, value : a.code, price : a.prixHT };}) : []} />
          </div>): <></>)}
      {(article && article.editable && item.qte) ?
        (<div className='d-flex justify-content-between align-items-center mb-2'>
          <label className='w-50 m-0' htmlFor={priceItem}>Montant personnalisé</label>
          <InputField name={priceItem} type='number' className='w-50' precision={0} min={item.prixUnitaireHt} defaultValue={item.prixPersonalise ? item.prixPersonalise : item.prixUnitaireHt} />
        </div>)
        : <></>}
    </div>
  )
}