import { Bilan } from '../display/Bilan';
import { BilanBudget } from '../display/BilanBudget';
import { Prestations } from '../display/Prestations';
import { WipozTitle } from '../display/WipozTitle';
import { SubFormAnchor, SubmitButton } from '../form/SubmitButton';


export const PrestationsPage = () => {
    return (
        <>
            <div className='d-flex flex-column flex-md-row align-items-center justify-content-between mb-3 m-lg-0'>
                <WipozTitle />
                <SubFormAnchor submit={true} targetFormName='principal' className='fw-bold text-white text-decoration-none text-nowrap'>
                    <>
                        <span className='material-icons'>chevron_left</span> Modifier mon projet
                    </>
                </SubFormAnchor>
            </div>
            <div className='row'>
                <div className='col-lg-7'>
                    <div className='card mb-3' >
                        <div className='card-header'>
                            <i className='material-icons text-secondary'>list</i> Votre projet Cuisine : récapitulatif
                        </div>
                        <div className='card-body'>
                            <Bilan />
                        </div>
                        <div className='card-footer justify-content-end gap-3'>
                            <BilanBudget />
                        </div>
                    </div>
                </div>
                <div className='col-lg-5'>
                    <div className='card card-sticky'>
                        <Prestations />
                    </div>
                </div>
            </div>
            <div className='py-3 text-center'>
                <SubmitButton className='btn btn-primary w-50 d-flex justify-content-center align-items-center gap-2 mx-auto'>
                    <>
                        <span className='material-icons'>check</span> Valider
                    </>
                </SubmitButton>
            </div>

        </>);
}