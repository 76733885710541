import { useAuthProvider } from 'leeloo-react/component/hook/useAuthProvider';
import { useRouter } from 'leeloo-react/component/hook/useRouter';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

export const PublicProjet = () => {
    const authProvider = useAuthProvider();
    const router = useRouter();
    const { id } = useParams<any>();
    useEffect(() => {
        if (!authProvider || !authProvider.authData || !authProvider.authData.token) {
            localStorage.setItem('source-projet-id', id);
            router.goTo('/projet/:id', { id: id });
        }
    }, []);
    return (<></>);
}