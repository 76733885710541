import { ContextForm } from 'leeloo-react/component/form/Form'
import { ContextSubForm } from 'leeloo-react/component/form/subForm/SubForm';
import { useContext } from 'react'
import { Implantation } from '../../model/api-dto';
import { DevisConfiguratorType } from '../../model/configurator/DevisConfiguratorType';
import { SubFormAnchor } from '../form/SubmitButton';

/**
 * Affiche un item du configurateur
 */
export const CaracteristiqueResume = () => {
  const { contextFormParent } = useContext(ContextSubForm);
  const principal = contextFormParent.values?.principal as DevisConfiguratorType;
  let implantation = 'non défini';
  if(principal.implantation == Implantation.U)
  {
    implantation = 'U';
  }
  else if(principal.implantation == Implantation.L)
  {
    implantation = 'L';
  }
  else if(principal.implantation == Implantation.Lineaire)
  {
    implantation = 'Linéaire';
  }
  return (
    <>
    <h2 className='h2'>Caractéristiques de votre projet :</h2>
    <span className='text-nowrap'>Meubles montés : <strong>{principal.meubleMonte ? 'Oui' : 'Non'}</strong></span>
    &nbsp;&nbsp;•&nbsp;&nbsp;<span className='text-nowrap'>Modèles sans poignées : <strong>{principal.sansPoignee ? 'Oui' : 'Non'}</strong></span>
    &nbsp;&nbsp;•&nbsp;&nbsp;<span className='text-nowrap'>Îlot central : <strong>{principal.ilotCentral ? 'Oui' : 'Non'}</strong></span>
    &nbsp;&nbsp;•&nbsp;&nbsp;<span className='text-nowrap'>Marque des meubles : <strong>{principal.marque ? principal.marque : 'non défini' }</strong></span>
    &nbsp;&nbsp;•&nbsp;&nbsp;<span className='text-nowrap'>Implantation : <strong>{implantation}</strong></span>
    &nbsp;&nbsp;•&nbsp;&nbsp;<SubFormAnchor submit={true} targetFormName='principal' className='text-primary text-decoration-none text-nowrap'><>Modifier</></SubFormAnchor>
    </>
  );
};
