import { ContextForm } from 'leeloo-react/component/form/Form';
import { useContext } from 'react';
import { DevisConfiguratorType } from '../../model/configurator/DevisConfiguratorType';
import { SubFormAnchor } from '../form/SubmitButton';
import { useResolveItem } from '../hooks/useResolveItem';

export const Panier = () => {
  const form = useContext(ContextForm);
  const values = form.values as DevisConfiguratorType;
  const itemResolver = useResolveItem(true);

  return (
    <>
      <div className='card-header'>
        <span className='material-icons text-secondary'>list</span> Votre projet Cuisine
      </div>
      <div className='card card-panier card-body card-body-overflow'>
        <table className='table'>
          <tbody>
            {values?.itemList?.map((el, index) => {
              const currentItem = itemResolver(index);
              return (
                <tr key={index}>
                  <th>{currentItem.libelle}</th>
                  <td className='text-end'>x{currentItem.qte}</td>
                  <td className='text-end'>
                    <strong className='text-primary text-nowrap'>{currentItem.prixHt!} €</strong>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      <div className='card-footer'>
        <SubFormAnchor submit={true} targetFormName='prestations' className='fw-bold text-white text-decoration-none text-nowrap'>
          <>
            Etape suivante <span className='material-icons'>chevron_right</span>
          </>
        </SubFormAnchor>
        {/* <SubmitBtn label='Enregistrer' /> */}
        <div className='ms-auto'>
          <small>Total HT :</small> <strong>{values?.itemList?.reduce((pv, cv, i) => itemResolver(i).prixHt + pv, 0)} €</strong>
        </div>
      </div>
    </>
  )
}
