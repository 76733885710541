import { ContextForm } from 'leeloo-react/component/form/Form'
import { ContextSubForm } from 'leeloo-react/component/form/subForm/SubForm';
import { useContext } from 'react'
import { DevisConfiguratorType, PrestationConfiguratorType } from '../../model/configurator/DevisConfiguratorType';
import { useResolveItem } from '../hooks/useResolveItem';
import { useResolvePrestation } from '../hooks/useResolvePrestation';

/**
 * Affiche un item du configurateur
 */
export const BilanBudget = () => {
  const form = useContext(ContextForm);
  const { contextFormParent } = useContext(ContextSubForm);
  const produits = contextFormParent.values?.principal as DevisConfiguratorType;
  const prestations = form.values as PrestationConfiguratorType;
  const itemResolver = useResolveItem();
  const prestationResolver = useResolvePrestation();

  return (
    <>Budget projet HT <strong>
              {produits?.itemList?.reduce((pv, cv, i) => itemResolver(i).prixHt + pv, 0) + prestations?.itemList?.reduce((pv, cv, i) => prestationResolver(i).prixHt + pv, 0) + (prestations?.custom.enabled ? prestations.custom.price : 0)} €</strong>
    </>
  );
};
