import { LoadContent } from 'leeloo-react/component/display/LoadContent';
import { useDataProvider } from 'leeloo-react/component/hook/useDataProvider';
import { usePageContext } from 'leeloo-react/component/hook/usePageContext';
import { useRouter } from 'leeloo-react/component/hook/useRouter';
import { NetDataProvider } from 'leeloo-react/dataProvider/NetDataProvider';
import { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { ResourceApiEnum } from '../../enum/RessourceApiEnum';

type Props = {
    item: any
}

const ProjetItem = (props: Props) => {
    const router = useRouter();
    return (<div className='configurator-item pointer' onClick={() => {
        router.goTo('/projet/' + props.item.id);
    }}>
        <div className='head'>
            <img
                src={'/assets/img/plansdetravail.jpg'}
                alt={'item.libelle'}
                className='thumb'
            />
            <span className='title'>{props.item.label ? props.item.label : 'indefini'}</span>
            {/* <span className='title'>{props.item.price} €</span> */}
            <div className='input-group foot'>
                {props.item.price} €
            </div>
        </div>
    </div>)
}

const NewProjetItem = () => {
    const router = useRouter();
    return (<div className='configurator-item pointer' title='Nouveau projet' onClick={() => {
        router.goTo('/projet/');
    }}>
        <div className='head bg-primary'>
            <div className='thumb fg-white'>
                <span className='material-icons'> create_new_folder </span>
            </div>
        </div>
    </div>)
}

export const MyProjets = () => {
    const router = useRouter();
    
    const configuration = usePageContext<any>({ projects: [] });
    const dpNetApi = useDataProvider({ customDp: NetDataProvider });
    useEffect(() => {
        const id = localStorage.getItem('source-projet-id');
        if(id)
        {
            localStorage.removeItem('source-projet-id');
            router.goTo('/projet/:id', {id: id});
            return ;
        }
        dpNetApi.getList!(ResourceApiEnum.listProjects, {}).then((response) => {
            configuration.dispatch.setGlobal({ projects: response.sourceData });
        });
    }, []);
    if (!configuration.data?.projects) {
        return (<LoadContent isLoad={true}></LoadContent>);
    }
    else {
        return (
            <>
                <header>
                    <img src='/assets/img/logo-wipoz.png' alt='Wipoz!' className='logo' />
                    <div className='intro'>
                        <h1 className='h1'>Outil de chiffrage</h1>
                        <h2>Mes projets</h2>
                    </div>
                </header>
                <div className='configurator-grid small'>
                    {configuration.data!.projects.map((el: any, index: number) => (<ProjetItem key={index} item={el} />))}
                    <NewProjetItem />
                </div>
            </>);
    }
}