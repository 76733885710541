import { RouteConfig } from './config/RouteConfig'
import { MenuRouteConfig } from './config/MenuRouteConfig'
import { LeelooSpa } from 'leeloo-react/LeelooSpa'
import './scss/main.scss'
import 'bootstrap'
import { StoreConfig } from 'leeloo-react/config/StoreConfig'
import { ODataProvider } from 'leeloo-react/dataProvider/ODataProvider'
import { JwtAuthProvider } from 'leeloo-react/authProvider/JwtAuthProvider'
import { MyLoginForm } from './components/form/LoginForm'

function App() {
    return (
    <div className='App'>
      <LeelooSpa
        dataProvider={ODataProvider}
        authProvider={JwtAuthProvider}
        apiUrl={process.env.REACT_APP_API_URL ?? ''}
        routeConfig={RouteConfig}
        menuConfig={MenuRouteConfig}
        storeConfig={StoreConfig}
        loginForm={MyLoginForm}

        defaultPrivateRoute={() => {
          return '/';
        }}

      />
    </div>
  )
}
export default App

