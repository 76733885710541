import { ContextForm } from 'leeloo-react/component/form/Form'
import { useContext } from 'react'
import { DevisConfiguratorType } from '../../model/configurator/DevisConfiguratorType';
import { ConfiguratorItem } from './ConfiguratorItem'

/**
 * Affiche un item du configurateur
 */
export const Configurator = () => {
  const form = useContext(ContextForm);
  const values = form.values as DevisConfiguratorType;

  return (
    <div className='configurator-grid'>
      {values?.itemList?.map((el, index) => (
          <ConfiguratorItem key={index} index={index}  />
        ))
      }
    </div>
  );
};
