import { ContextSubForm } from 'leeloo-react/component/form/subForm/SubForm';
import { useContext } from 'react';
import { DevisConfiguratorType } from '../../model/configurator/DevisConfiguratorType';

export const WipozTitle = () => {
    const { contextFormParent } = useContext(ContextSubForm);
    const principal = contextFormParent.values?.principal as DevisConfiguratorType;
  
    return (<header>
        <img src='/assets/img/logo-wipoz.png' alt='Wipoz!' className='logo' />
        <div className='intro'>
            <h1 className='h1'>Chiffrage cuisine</h1>
            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
            <p>{principal.label ? principal.label  : <i>Contremarque</i>}</p>
        </div>
    </header>);
}