import { ContextForm } from 'leeloo-react/component/form/Form'
import { ContextSubForm } from 'leeloo-react/component/form/subForm/SubForm';
import { useContext } from 'react'
import { DevisConfiguratorType, PrestationConfiguratorType } from '../../model/configurator/DevisConfiguratorType';
import { useResolveItem } from '../hooks/useResolveItem';
import { useResolvePrestation } from '../hooks/useResolvePrestation';
import { CaracteristiqueResume } from './CaracteristiqueResume';

/**
 * Affiche un item du configurateur
 */
export const Bilan = () => {
  const form = useContext(ContextForm);
  const { contextFormParent } = useContext(ContextSubForm);
  const produits = contextFormParent.values?.principal as DevisConfiguratorType;
  const prestations = form.values as PrestationConfiguratorType;
  const itemResolver = useResolveItem();
  const prestationResolver = useResolvePrestation();

  return (
    <>
      <CaracteristiqueResume />
      <hr />
      <table className='table mb-0'>
        <thead>
          <tr>
            <th></th>
            <th className='text-end'>Prix unitaire HT</th>
            <th className='text-end'>Quantité</th>
            <th className='text-end'>Montant HT</th>
          </tr>
        </thead>
        <tbody>
          {produits?.itemList.map((p, index) => {
            const item = itemResolver(index);
            if (item.qte == 0) return (<></>);
            return (
              <tr key={'pp' + index}>
                <th>{item.libelle}</th>
                <td className='text-end'><span className='text-nowrap'>{item.prixUnitaireHt} €</span></td>
                <td className='text-end'>x{item.qte}</td>
                <td className='text-end'><strong className='text-primary text-nowrap'>{item.prixHt} €</strong></td>
              </tr>
            );
          })}

          {prestations?.itemList.map((p, index) => {
            const item = prestationResolver(index);
            if (!item.qte || item.prixHt == 0) return (<></>);
            return (
              <tr key={index}>
                <th>{item.article ? item.article.libelle : item.libelle}</th>
                <td className='text-end'><em className='text-nowrap text-muted'>Forfait</em></td>
                <td className='text-end'></td>
                <td className='text-end'><strong className='text-primary text-nowrap'>{item.prixHt} €</strong></td>
              </tr>
            );
          })}

          {(! prestations?.custom.enabled || (prestations?.custom.price == 0)) ? <></> : 
             (
              <tr key={'custom'}>
                <th>{prestations.custom.label ? prestations.custom.label : 'Autre prestation'}</th>
                <td className='text-end'><em className='text-nowrap text-muted'>Forfait</em></td>
                <td className='text-end'></td>
                <td className='text-end'><strong className='text-primary text-nowrap'>{prestations.custom.price} €</strong></td>
              </tr>
            )
          }
        </tbody>
      </table>
    </>
  );
};
