import { SimpleColumn } from 'leeloo-react/component/dataTable/column/SimpleColumn'
import { DataTable } from 'leeloo-react/component/dataTable/DataTable'
import { Filter } from 'leeloo-react/component/filter/Filter'
import { InputField } from 'leeloo-react/component/form/field/InputField'
import { ResourceApiEnum } from '../../../enum/RessourceApiEnum'
import { RouteEnum } from '../../../enum/RouteEnum'
import { BaseContainer } from '../../layout/BaseContainer'

export const Article = () => {
  return (
    <BaseContainer>
      <DataTable
        resource={ResourceApiEnum.article}
        //  {/* OPTIONNEL : Pré filtre initial de la dataTable */}
        // initialParams={{filter: {entities: {type:"equal", value: currentParamsRoute.idEntities}}}}
        // {/* OPTIONNEL : Définis affichage du bouton supprimer sous condition par ligne (ou toute dans ce cas de figure) */}
        allowDelete={(row) => true}
        addRoute={RouteEnum.ARTICLE_ADD}
        editRoute={RouteEnum.ARTICLE_EDIT}
        // {/* OPTIONNEL : Formulaire visuel pour les filtres */}
        filter={
          <Filter>
            <InputField label='Recherche' name='search' />
          </Filter>
        }
      >
        {/* DEFINITION DES COLONNES, UN COMPOSANT = UNE COLONNE */}
        <SimpleColumn title='Id' field='id' />
        <SimpleColumn title='Libelle' field='Libelle' sortable />
      </DataTable>
    </BaseContainer>
  )
}
