// src/components/layout/BaseContainer.tsx
import React from 'react'
import { RouterMenuLink } from 'leeloo-react/component/router/RouterMenuLink'
import { useRouter } from 'leeloo-react/component/hook/useRouter'

type Props = {
  children?: JSX.Element | JSX.Element[]
}

/**
 * Containeur de base de toute les pages
 */
export const BaseContainer = (props: Props) => {
  const { currentLabelRoute } = useRouter()
  return (
    <div>
      {/* TODO Ajouter le header/sidebar et autre composant commun au page */}
      {/* <RouterMenuLink />  {/* Génère le menu de lien de l'application */}
      {/* <h1>Vous êtes sur la page {currentLabelRoute}</h1> */}

      {props.children}
    </div>
  )
}
