import { Form } from 'leeloo-react/component/form/Form'
import { useDataProvider } from 'leeloo-react/component/hook/useDataProvider'
import { usePageContext } from 'leeloo-react/component/hook/usePageContext'
import { NetDataProvider } from 'leeloo-react/dataProvider/NetDataProvider'
import { ResponseHttpType } from 'leeloo-react/model/dataProvider/ResponseHttpType'
import { useEffect } from 'react'
import { ResourceApiEnum } from '../../enum/RessourceApiEnum'
import { ArticleDto, CategorieDto, EConfigurateurType, ECuisineType, Implantation } from '../../model/api-dto'
import { FormDevisContext } from '../../model/configurator/DevisConfiguratorType'
import { useDataSourceConfigurateur } from '../hooks/useDataSourceConfigurateur'
import { BaseContainer } from '../layout/BaseContainer'
import { useParams } from 'react-router-dom'
import { SubForm } from 'leeloo-react/component/form/subForm/SubForm'
import { PrincipalPage } from './PrincipalPage'
import { PrestationsPage } from './PrestationsPage'
import { useRouter } from 'leeloo-react/component/hook/useRouter'
import { useProjetInitialValue } from '../hooks/useProjetInitialValue'

export const HomePage = () => {
  // La config du tenant est stockée  ) voir avec Valentin
  //   const dispatchConfig = useWriteReducer<TenantReducerType>(ReducerEnum.Tenant);
  //   dispatchConfig.set("id",process.env.REACT_APP_API_URL);
  // const tenantId = useSelector((s: StateType) => s.tenant.id);
  const { dsConfigurateur } = useDataSourceConfigurateur(EConfigurateurType.Cuisine);
  const configuration = usePageContext<any>({ settings: { categories: [] } });
  const dpNetApi = useDataProvider({ customDp: NetDataProvider });
  const { id } = useParams<any>();
  const router = useRouter();
  useEffect(() => {
    if (id != null) {
      dpNetApi.getOne!(ResourceApiEnum.getProject, { id: id }).then((response) => {
        configuration.dispatch.setGlobal({ settings: dsConfigurateur.oneData, project: response.data });
      });
    }
    else {
      configuration.dispatch.setGlobal({ settings: dsConfigurateur.oneData });
    }

  }, [dsConfigurateur.oneData]);

  const initialValue = useProjetInitialValue(id);

  return (
    <BaseContainer>
      <div className='page-wrapper'>
        <Form<FormDevisContext>
          initialSubFormName='principal'
          initialValue={initialValue}
         
          
          onSubmit={async (d) => {
            const customPrestation:any = null;
            if (d.id != null) {
              const updateProjet = {
                customPrestation: customPrestation,
                id: d.id,
                label: d.principal.label,
                marque: d.principal.marque,
                notes: d.prestations.notes,
                iloCentral: d.principal.ilotCentral,
                meubleSansPoignee: d.principal.sansPoignee,
                implantation: d.principal.implantation,
                livraisonKit: !d.principal.meubleMonte,
                Products: d.principal.itemList.filter(v => v.qte > 0).map((v, index) => {
                  const cuisineType = d.principal.meubleMonte ? ECuisineType.Monte : ECuisineType.Kit;
                  const article = configuration.data?.settings?.articles?.find((a: ArticleDto) => {
                    return a.categorieId == v.categoryId && (a.cuisineType == cuisineType || a.cuisineType == ECuisineType.LesDeux)
                  });
                  return {
                    Code: article!.code,
                    Quantity: v.qte
                  };
                })
              };
              const prestations = d.prestations.itemList.filter(p => p.qte > 0 && p.codeItem).map((p, index) => {

                return {
                  Code: p.codeItem,
                  SellingPriceHT : p.price,
                  Quantity: p.qte ? 1 : 0
                };
              });
              if (prestations) {
                updateProjet.Products = updateProjet.Products.concat(prestations);
              }
              if(d.prestations.custom.enabled && d.prestations.custom.price > 0) {
                updateProjet.customPrestation = {
                  label : d.prestations.custom.label ? d.prestations.custom.label : 'Autre prestation',
                  price : d.prestations.custom.price
                };
              }

              dpNetApi.update!(ResourceApiEnum.updateProject, { data: updateProjet, id: d.id }).catch(() => {
                console.log('an error occured during update');
              }).then(() => {router.goTo('/');});
            }
            else {
              const newProjet = {
                customPrestation: customPrestation,
                label: d.principal.label,
                marque: d.principal.marque,
                notes: d.prestations.notes,
                livraisonKit: !d.principal.meubleMonte,
                implantation: d.principal.implantation,
                iloCentral: d.principal.ilotCentral,
                meubleSansPoignee: d.principal.sansPoignee,
                Products: d.principal.itemList.filter(v => v.qte > 0).map((v, index) => {
                  const cuisineType = d.principal.meubleMonte ? ECuisineType.Monte : ECuisineType.Kit;
                  const article = configuration.data?.settings?.articles.find((a: ArticleDto) => {
                    return a.categorieId == v.categoryId && (a.cuisineType == cuisineType || a.cuisineType == ECuisineType.LesDeux)
                  });
                  return {
                    Code: article!.code,
                    Quantity: v.qte
                  };
                }),

                ConfigurateurType: EConfigurateurType.Cuisine
              };
              const prestations = d.prestations.itemList.filter(p => p.qte > 0 && p.codeItem).map((p, index) => {
                return {
                  Code: p.codeItem,
                  SellingPriceHT : p.price,
                  Quantity: p.qte ? 1 : 0
                };
              });
              if (prestations) {
                newProjet.Products = newProjet.Products.concat(prestations);
              }
              if(d.prestations.custom.enabled && d.prestations.custom.price > 0) {
                newProjet.customPrestation = {
                  label : d.prestations.custom.label ? d.prestations.custom.label : 'Autre prestation',
                  price : d.prestations.custom.price
                };
              }
              dpNetApi.create!(ResourceApiEnum.createProject, { data: newProjet, id: null }).catch(() => {
                console.log('an error occured during create');
              })
                .then((response: void | ResponseHttpType) => {
                  if (response) {
                    d.id = response.data;
                    router.goTo('/');
                  }
                });

            }
          }}
        >
          <>
            <SubForm name='principal'>
              <PrincipalPage isLoading={dsConfigurateur.isLoading} />
            </SubForm>
            <SubForm name='prestations'>
              <PrestationsPage />
            </SubForm>
          </>
        </Form>
      </div>
    </BaseContainer >
  )
}


