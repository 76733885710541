import { CheckField } from 'leeloo-react/component/form/field/CheckField'
import { InputField } from 'leeloo-react/component/form/field/InputField'
import { useResolvePrestation } from '../hooks/useResolvePrestation'

type Props = {
  index: number
}

/**
 * Affiche un item du configurateur
 */
export const PrestationItem = (props: Props) => {
  const prestationResolver = useResolvePrestation();
  const item = prestationResolver(props.index);
  const keyItem = 'itemList[' + props.index + '].qte';
  const priceItem = 'itemList[' + props.index + '].price';
  console.log('PrestationItem', item);

  if(!item.visible)
  { // on n'affiche pas la prestation si
    // la prestation n'est pas editable ou sinon si la qte est = 0
    return (<></>);
  }
  return (
    <div>
      <div className='form-check form-switch mb-2'>
        <label className='form-check-label'>{item.libelle}</label>
        <CheckField name={keyItem} disabled={item.readOnly} />
      </div>
      {(item.editable && item.qte) ?
        (<div className='d-flex justify-content-between align-items-center mb-2'>
          <label className='w-50 m-0' htmlFor={priceItem}>Montant personnalisé</label>
          <InputField name={priceItem} type='number' className='w-50' precision={0} min={item.prixUnitaireHt} defaultValue={item.prixPersonalise ? item.prixPersonalise : item.prixUnitaireHt} />
        </div>)
        : <></>}
    </div>
  )
}