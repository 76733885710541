// src/config/RouteConfig.ts
import { RouteType } from 'leeloo-react/model/RouteType'
import { PublicProjet } from '../components/form/PublicProjet'
import { Article } from '../components/page/admin/Article'
import { HomePage } from '../components/page/HomePage'
import { MyProjets } from '../components/page/MyProjets'
import { RouteEnum } from '../enum/RouteEnum'
/**
 * Configuration des routes exprimé ici
 */
export const RouteConfig: RouteType[] = [
  {
    label: 'Authentification',
    icon: '',
    routeUrl: RouteEnum.HOME, 
    pageComponent: MyProjets,
    rolesAccess: ['user']
  },
  {
    label: 'Article',
    icon: '',
    routeUrl: RouteEnum.ARTICLE,
    pageComponent: Article,
    rolesAccess: ['admin']
  },
  {
    label: 'Accueil',
    icon: '',
    routeUrl: RouteEnum.ADD_EDIT_PROJET,
    pageComponent: HomePage,
    rolesAccess: ['user']
  },
  {
    label: 'public',
    icon: '',
    routeUrl: '/public-projet/:id',
    pageComponent: PublicProjet
  }
]
