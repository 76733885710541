import { LoadContent } from 'leeloo-react/component/display/LoadContent';
import { Caracteristique } from '../display/Caracteristique';
import { Configurator } from '../display/Configurator';
import { Panier } from '../display/Panier';
import { WipozTitle } from '../display/WipozTitle';


export type PrincipalProps = {
    isLoading: boolean
}

export const PrincipalPage = (props: PrincipalProps) => {
    return (
        <div className='row'>
            <div className='col-lg-7'>
                <WipozTitle />
                <LoadContent isLoad={props.isLoading}>
                    <Configurator />
                </LoadContent>
            </div>
            <div className='col-lg-5'>
                <div className='card card-sticky'>
                    <Caracteristique />
                    <Panier />
                </div>
            </div>
        </div>
    );
}