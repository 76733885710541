import { usePageContext } from 'leeloo-react/component/hook/usePageContext';
import { ArticleDto, ArticleType, CategorieDto, Implantation } from '../../model/api-dto';
import { FormDevisContext } from '../../model/configurator/DevisConfiguratorType';

export function useProjetInitialValue(id: number | null): FormDevisContext {
    const configuration = usePageContext<any>({ settings: { categories: [] } });
    const CategoryMapper = function (c: CategorieDto) {
        if(c.articleType == ArticleType.PrestationComplementaire)
        {
            return null;
        }

        const article = configuration!.data!.settings!.articles!.find((a: ArticleDto) => a.categorieId == c.id);
        if (!article) {
            return null;
        }
        let qty = 0;
        if (configuration!.data!.project?.products) {
            const product = configuration!.data!.project!.products!.find((p: any) => p.code == article!.code);
            qty = product ? product!.quantity : 0;
        }
        return {
            codeItem: article!.code,
            categoryId: c.id,
            qte: qty
        }
    };

    const PrestationMapper = function (a: ArticleDto) {
        let qty = 0;
        let price: number | null = null;

        if (configuration!.data!.project?.products) {
            const product = configuration!.data!.project!.products!.find((p: any) => p.id == a!.id);
            qty = product ? product!.quantity : 0;
            if (a.editable && product && product.sellingPriceHT != a.prixHT) {
                price = product.sellingPriceHT;
            }
        }
        else if (a.mandatory) {
            qty = 1;
        }

        return {
            codeItem: a.code,
            id: a.id,
            categoryId : 0,
            qte: qty > 0,
            price: price
        }
    };

    const standardPrestations = (configuration?.data?.settings?.articles) ? configuration!.data!.settings!.articles!.filter((a: ArticleDto) => a.kind == ArticleType.PrestationComplementaire && a.categorieId == 0).map((a: ArticleDto) => {
        return PrestationMapper(a);
    }).filter((e: any) => { return (e != null); }) : [];

    const listPrestations = (configuration?.data?.settings?.categories) ? configuration!.data!.settings!.categories!.filter((c: CategorieDto) => c.articleType == ArticleType.PrestationComplementaire).map((c: CategorieDto) => {
        const articles = (configuration?.data?.settings?.articles) ? configuration!.data!.settings!.articles!.filter((a: ArticleDto) => a.categorieId == c.id) : [];
        let produit : any = null;
        if(configuration?.data?.project) {
            produit = configuration!.data!.project!.products!.find((p: any) => {
                return articles.find((a: ArticleDto) => p.id == a.id);
            });
        }
        return {
            codeItem:produit?.code,
            categoryId : c.id,
            qte : produit ? true : false,
            price : produit?.sellingPriceHT,
            label : c.libelle,
            articles : articles
        };
    }).filter((e: any) => { return (e != null); }) : [];
    return {
        id: id,
        principal: {
            label: configuration?.data?.project ? configuration!.data!.project!.label : '',
            implantation: (configuration?.data?.project ? configuration!.data!.project!.implantation : Implantation.Unknown),
            sansPoignee: (configuration?.data?.project ? configuration!.data!.project!.meubleSansPoignee : false),
            ilotCentral: (configuration?.data?.project ? configuration!.data!.project!.ilotCentral : false),
            meubleMonte: (configuration?.data?.project ? !configuration!.data!.project!.livraisonKit : true),
            marque: (configuration?.data?.project ? configuration!.data!.project!.marque : ''),
            itemList: (configuration?.data?.settings?.categories) ? configuration!.data!.settings!.categories!.map((c: CategorieDto) => {
                return CategoryMapper(c);
            }).filter((e: any) => { return (e != null); }) : []
        },
        prestations: {
            itemList: standardPrestations.concat(listPrestations),
            custom: {
                label: configuration?.data?.project?.customPrestation ? configuration?.data?.project?.customPrestation.label : '',
                price: configuration?.data?.project?.customPrestation ? configuration?.data?.project?.customPrestation?.price : 0, 
                enabled: configuration?.data?.project?.customPrestation ? configuration?.data?.project?.customPrestation.price > 0 : false,
                code: 'custom',
                id: 0
            },
            notes: (configuration?.data?.project ? configuration!.data!.project!.notes : '')
        }
    }
}