import { ContextForm } from 'leeloo-react/component/form/Form';
import { ContextSubForm } from 'leeloo-react/component/form/subForm/SubForm';
import { useContext } from 'react';

/**
 * Simple bouton de soumission du formulaire
 */

export declare type PropSubmitButtonType = {
    className?: string;
    /** Renseigne au Form l'origine du bouton qui vient de valider le formulaire, permet des actions différente selon le tag du bouton */
    tagSubmit?: string;
    children?: JSX.Element | JSX.Element[];
};

export const SubmitButton = (props: PropSubmitButtonType) => {
    const { form, isLoad, isDisabledSubmit, submitBtnTag } = useContext(ContextForm);

    return (
        <button
            disabled={isLoad || isDisabledSubmit}
            className={props.className}
            onClick={() => {
                if (props.tagSubmit) {
                    submitBtnTag!.current = props.tagSubmit;
                }
                form.handleSubmit();
            }}
        >
            {props.children}
        </button>
    )
}

type Props = PropSubmitButtonType & {
    /** Identifiant name d'un SubForm pour basculer sur celui ci, le updateBeforeChangeSubForm du SubForm sera appellé avant */
    targetFormName: string,
    submit?: boolean
}



export const SubFormAnchor = (props: Props) => {
    const { contextFormParent } = useContext(ContextSubForm);
    if (! props.submit) {
        return (
            <button
                className={'btn-subform-anchor ' + props.className}
                onClick={() => {
                    contextFormParent.setDisplaySubForm(props.targetFormName);
                }}
            >
                {props.children}
            </button>
        );
    }
    else {
        return <SubmitButton {...props} className={'btn-subform-anchor ' + props.className} tagSubmit={props.targetFormName} />;
    }
}