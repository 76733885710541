import { ContextForm } from 'leeloo-react/component/form/Form';
import { ContextSubForm } from 'leeloo-react/component/form/subForm/SubForm';
import { usePageContext } from 'leeloo-react/component/hook/usePageContext';
import { useContext } from 'react';
import { ConfigurateurDto, ECuisineType, EVisibiliy } from '../../model/api-dto';
import { DevisConfiguratorType, PrestationConfiguratorType } from '../../model/configurator/DevisConfiguratorType';

function useArticles() {
    const configuration = usePageContext<any>();
    const settings = configuration.data?.settings as ConfigurateurDto;
    const lookupById = function (id: number) {
        return settings?.articles.find(a => a.id == id);
    };

    const lookupByCode = function (code: string) {
        return settings?.articles.find(a => a.code == code);
    };

    return {
        lookupById: lookupById,
        lookupByCode: lookupByCode
    };
}

export function useResolvePrestation() {
    const form = useContext(ContextForm);
    const { contextFormParent } = useContext(ContextSubForm);
    const principal = contextFormParent.values.principal as DevisConfiguratorType;
    const { lookupById, lookupByCode } = useArticles();
    return (index: number) => {
        const formValues = form.values as PrestationConfiguratorType;
        const itemValue = formValues.itemList[index];
        if (itemValue.categoryId != 0) {
            // Traitement des groupes de prestation
            const article = lookupByCode(itemValue.codeItem);
            return {
                codeItem: article?.code,
                libelle: itemValue?.label,
                article: article,
                qte: itemValue.qte,
                prixUnitaireHt: itemValue.price,
                prixPersonalise: itemValue.price,
                readOnly: false,
                editable: false,
                prixHt: itemValue.qte > 0 ? (itemValue.price ? itemValue.price : 0) : 0,
                articles: itemValue?.articles,
                visible: true
            };
        }
        else {
            const article = lookupById(itemValue.id);
            let visible: boolean = article!.visibility == EVisibiliy.Visible || article!.visibility == EVisibiliy.ReadOnly;
            let readOnly: boolean = article!.mandatory || article!.visibility == EVisibiliy.ReadOnly;
            if (article && ((article.cuisineType & ECuisineType.ModeleSansPoignee) == ECuisineType.ModeleSansPoignee)) {   // cas particulier de l'article associé à meuble sans poignée
                const type = principal.meubleMonte ? ECuisineType.Monte : ECuisineType.Kit;
                itemValue.qte = (((article.cuisineType & type) == type) && principal.sansPoignee) ? 1 : 0;
            }

            if(article && !(((article.cuisineType & ECuisineType.Kit) == ECuisineType.Kit) && ((article.cuisineType & ECuisineType.Monte) == ECuisineType.Monte)))
            {
                const type = principal.meubleMonte ? ECuisineType.Monte : ECuisineType.Kit;
                visible = ((article.cuisineType & type) == type);
                if(article.mandatory) {
                    itemValue.qte = visible ? 1 : 0;
                }
            }

            if (!visible && article!.editable) {
                visible = itemValue.qte > 0;
                readOnly = true;
            }

            return {
                codeItem: itemValue.codeItem,
                libelle: article?.libelle,
                qte: itemValue.qte,
                prixUnitaireHt: article!.prixHT,
                prixPersonalise: itemValue.price,
                readOnly: readOnly,
                editable: article!.editable,
                visible: visible,
                prixHt: itemValue.qte > 0 ? (itemValue.price ? itemValue.price : article!.prixHT) : 0
            };
        }
    };
}