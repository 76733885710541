/**
 * Enumeration des routes disponibles ici
 */
export enum RouteEnum {
  ADD_EDIT_PROJET = '/projet/:id?',
  HOME = '/',
  LOGIN = '/login',
  ARTICLE = '/admin/article',
  ARTICLE_EDIT = '/admin/article/:id/edit',  
  ARTICLE_ADD = '/admin/article/:id/edit',
}
