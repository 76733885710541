import { ContextForm } from 'leeloo-react/component/form/Form';
import { ContextSubForm } from 'leeloo-react/component/form/subForm/SubForm';
import { usePageContext } from 'leeloo-react/component/hook/usePageContext';
import { useContext } from 'react';
import { ConfigurateurDto, ECuisineType } from '../../model/api-dto';
import { DevisConfiguratorType } from '../../model/configurator/DevisConfiguratorType';

export function useResolveItem(principal?:boolean) {
    let formValues :DevisConfiguratorType;
    if(principal)
    {
        const form = useContext(ContextForm);
        formValues =form.values as DevisConfiguratorType;
    }
    else{
        const { contextFormParent } = useContext(ContextSubForm);
        formValues =contextFormParent.values.principal as DevisConfiguratorType;
    }
    
    const configuration = usePageContext<any>();
    const settings = configuration.data?.settings as ConfigurateurDto;

    return (index: number) => {
        const itemValue = formValues.itemList[index];
        const itemRef = settings?.categories.find(c => itemValue.categoryId == c.id);

        const cuisineType = formValues.meubleMonte ? ECuisineType.Monte : ECuisineType.Kit;
        const article = settings?.articles.find(a => a.categorieId == itemValue.categoryId && ((a.cuisineType & cuisineType) == cuisineType));
        return {
            codeItem: itemValue.codeItem,
            image: itemRef?.imageRelativePath,
            libelle: itemRef?.libelle,
            qte: itemValue.qte,
            prixUnitaireHt: article!.prixHT,
            prixHt: itemValue.qte * article!.prixHT
        };
    };
}