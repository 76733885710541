import { useDataSource } from 'leeloo-react/component/hook/useDataSource'
import { ResourceApiEnum } from '../../enum/RessourceApiEnum'
import { ConfigurateurDto } from '../../model/api-dto'


export function useDataSourceConfigurateur(configurateurType: number) {
  
  const dsConfigurateur = useDataSource<ConfigurateurDto>({
    resource: ResourceApiEnum.configurateur,
    singleResult: true,
    params: {
      id: null,
      uriParameters: { configurateurType: configurateurType },
    },
  });

  return {
    dsConfigurateur
  };
}
