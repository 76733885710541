import { CheckField } from 'leeloo-react/component/form/field/CheckField';
import { InputField } from 'leeloo-react/component/form/field/InputField';
import { TextareaField } from 'leeloo-react/component/form/field/TextareaField';
import { ContextForm} from 'leeloo-react/component/form/Form'
import { useContext } from 'react'
import { PrestationConfiguratorType } from '../../model/configurator/DevisConfiguratorType';
import { PrestationItem } from './PrestationItem';
import { PrestationList } from './PrestationList';
/**
 * Affiche un item du configurateur
 */
export const Prestations = () => {
  const form = useContext(ContextForm);
  const values = form.values as PrestationConfiguratorType;
  return (
    <>
      <div className='card-header'>
        <span className='material-icons text-secondary'>add_circle</span> Prestations complémentaires
      </div>
      <div className='card-body card-body-overflow'>
        {values?.itemList?.map((el, index) => {
          if (el.categoryId == 0)
          {
              return (<PrestationItem key={index} index={index} />);
          }
          else
          {
            return (<PrestationList key={index} index={index} />);
          }
        })
        }
        
        <div className='form-check form-switch mb-2'>
          <label className='form-check-label'>Autre prestation</label>
          <CheckField name={'custom.enabled'} />
        </div>
        {values.custom.enabled ? (
          <>
            <div className='d-flex justify-content-between align-items-center mb-2'>
              <label className='w-50 m-0'>Libellé</label>
              <InputField name={'custom.label'} type='text' className='w-50' />
            </div>
            <div className='d-flex justify-content-between align-items-center mb-2'>
              <label className='w-50 m-0'>Montant</label>
              <InputField name={'custom.price'} type='number' className='w-50' precision={0} min={0} />
            </div>
            </>) 
            : <></>}

        <label className='form-label'>Notes / commentaires</label>
        <TextareaField<PrestationConfiguratorType> name={'notes'} minRows={5} maxRows={5} />
      </div>
      {/* <div className='card-footer'>
        <SubmitSubFormLink targetFormName='principal' label='Produits' className='form-link'/>
        <div className='ms-auto'>
          <small>Total HT :</small> <strong>{values?.itemList?.reduce((pv, cv, i) => prestationResolver(i).prixHt + pv, 0)} €</strong>
        </div>
      </div> */}
    </>
  );
};
